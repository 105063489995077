p {
    margin-bottom: 5px;
    display: inline-block;
}
a {
    color:lightskyblue;
}
.container {
    position: relative;
    width: 100%;
    color: white;
    font-size: 16px;
    font-family: PingFangSC-Light, sans-serif; 
}
.enroll {
    width: 100%;
}
.info {
    position: absolute;
    width: 400px;
    height: 100px;
    left: 45%;
    right: 0;
    margin: auto;
    text-align: left;
}
.register {
    bottom: 50%;
    top: 0;
}
.apply {
    bottom: 5%;
    top: 0;
}
.collaborate {
    bottom: 0;
    top: 40%;
}
.svg{
    width: 50px;
    position: absolute;
    left: -65px;
    margin: auto;
}
.email {
    position: absolute;
    right: 15%;
    bottom: 10%;
    margin: 0;
    color: royalblue
}
.qr {
    width: 80px;
    height: 80px;
}