.container {
    width: 100%;
    height: 100%;
    text-align: center;
    font-size: 14px;
    color: white;
    font-family: PingFangSC-Light, sans-serif; 
}
.enroll {
    width: 100%;
}
.info {
    position: relative;
    width: 100%;
    text-align: center;
    margin-top: 80px;
    margin-bottom: 50px;
    vertical-align: middle;
}
.svg {
    margin-right: 65px;
    width: 30px;
    margin-bottom: 10px;
}
.title {
    position: absolute;
    display: inline-block;
    left:47%;
    top: 5%;
}
.qr {
    position: absolute;
    bottom: 30%;
    left: 20%;
    padding: 20px 25px 0 25px;
}
.email {
    color: royalblue;
}
