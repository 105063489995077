.container {
    position: fixed;
    z-index: 1;
    height: 290px;
    right: 0;
    bottom: 40%;
}
.qr {
    width: 200px;
    height: 200px;
    position: fixed;
    top: 30%;
    left: 42%;
}
.qr img {
    width: 100%;
}
.item {
    height: 20px;
}